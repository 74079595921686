<template>
  <div>
    <v-row class="mx-1">
      <v-col cols="12">
        <v-card id="card-custom" class="px-2 py-4 rounded-lg">
          <v-card-actions>
            <p class="headline-color px-2">Pengaturan Mesin Absen</p>
          </v-card-actions>
          <v-divider />
          <v-row class="mx-2">
            <v-col cols="12" xl="9" lg="9" md="9" sm="6" xs="12" class="px-0">
              <v-card-actions>
                <v-btn
                  text
                  class="text-capitalize caption headline-color"
                  color="primary"
                  @click="
                    visible = true;
                    isEdit = false;
                  "
                  >{{ isEdit ? "Ubah" : "Tambah" }} Device<v-icon
                    class="ml-2"
                    color="primary"
                    >add_circle</v-icon
                  ></v-btn
                >
              </v-card-actions>
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="3" sm="6" xs="12" class="px-0">
              <v-text-field
                placeholder="Cari"
                v-model="search"
                outlined
                dense
                hide-details
                clearable
                prepend-inner-icon="search"
                class="mx-2"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mx-2 mb-4">
            <v-col cols="12" xl="3" lg="3" md="3" sm="6" xs="12" class="pa-0">
              <v-menu
                v-model="menuStart"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateStart"
                    placeholder="Mulai"
                    append-icon="event"
                    outlined
                    dense
                    hide-details
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    class="mx-2"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dateStart"
                  locale="id"
                  no-title
                  scrollable
                  @input="menuStart = false"
                />
              </v-menu>
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="3" sm="6" xs="12" class="pa-0">
              <v-menu
                v-model="menuEnd"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateEnd"
                    placeholder="Mulai"
                    append-icon="event"
                    outlined
                    dense
                    hide-details
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    class="mx-2"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dateEnd"
                  locale="id"
                  no-title
                  scrollable
                  @input="menuEnd = false"
                />
              </v-menu>
            </v-col>
            <v-col cols="12" xl="2" lg="2" md="2" sm="6" xs="12" class="pa-0">
              <v-btn
                color="primary"
                class="px-9 mx-2 font-weight-bold"
                @click="syncManualBulk"
                :disabled="selected && selected.length == 0"
              >
                SYNC
                <v-icon right dark>sync</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-data-table
            id="table-custom"
            :headers="headers"
            :items="machines"
            :loading="loading"
            :options.sync="options"
            :server-items-length="totalItem"
            :footer-props="{
              'items-per-page-options': rowsPerPageItems
            }"
            loading-text="Loading... Please wait"
            v-model="selected"
            :show-select="selection"
          >
            <template v-slot:[`item.unit`]="{ item }">
              <div class="py-1" style="min-width: 150px">
                <p class="mb-0">
                  <b>Unit Utama: </b>{{ item.unit_utama_name }}
                </p>
                <p class="mb-0">
                  <b>Unit Kerja 2: </b>{{ item.unit_kerja_2_name }}
                </p>
              </div>
            </template>
            <template v-slot:[`item.ip`]="{ item }">
              <div class="py-1" style="min-width: 150px">
                <p class="mb-0">
                  <b>{{ item.alias_name }}</b>
                </p>
                <p class="mb-0">
                  {{ item.ip }}
                </p>
              </div>
              <v-progress-linear
                v-if="item.loadingSync"
                indeterminate
                color="cyan"
              ></v-progress-linear>
            </template>
            <template v-slot:[`item.online`]="{ item }">
              <v-progress-linear
                v-if="item.loading"
                indeterminate
                color="cyan"
              ></v-progress-linear>
              <span v-else>
                <template v-if="item.error">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon color="error">info</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ item.error }}</span>
                  </v-tooltip>
                </template>
                <template v-else>
                  <span
                    :class="
                      item.online == 'Online'
                        ? 'success-color'
                        : 'subtitle-color'
                    "
                    >{{ item.online }}</span
                  >
                </template>
              </span>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <span v-html="item.status" />
            </template>
            <template v-slot:[`item.sync_terakhir`]="{ item }">
              <span v-html="item.sync_terakhir"></span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                x-small
                color="success"
                @click="handleClickLog(item)"
                class="mr-2 my-1"
                >Log Presensi</v-btn
              >
              <v-btn
                x-small
                color="primary"
                :loading="item.loading"
                @click="refreshStatus(item)"
                class="mr-2 my-1"
                >Sync Status</v-btn
              >
              <v-btn
                x-small
                color="#297BBF"
                class="mr-2 my-1 white--text"
                @click="handleEdit(item)"
                >Ubah</v-btn
              >
              <v-btn
                x-small
                color="#FBB005"
                class="mr-2 my-1 white--text"
                @click="handleDelete(item)"
                >Hapus</v-btn
              >
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="visible" persistent max-width="400">
      <v-card class="pa-5 rounded-lg" id="card-custom">
        <v-btn @click="visible = false" x-small icon class="float-right">
          <v-icon>close</v-icon>
        </v-btn>
        <v-card-text class="subtitle-2 px-0 font-weight-regular">
          {{ isEdit ? "Ubah" : "Tambah" }} Device
        </v-card-text>
        <v-divider class="mb-4" />
        <v-form
          id="form"
          ref="form"
          v-model="valid"
          lazy-validation
          @submit.prevent="save()"
        >
          <v-row>
            <v-col cols="5" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >IP Address</v-subheader
              >
            </v-col>
            <v-col cols="7" class="py-1">
              <v-text-field
                v-model="form.ip"
                outlined
                dense
                :disabled="isEdit"
                :rules="[v => !!v || 'IP Address harus diisi']"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Alias Name</v-subheader
              >
            </v-col>
            <v-col cols="7" class="py-1">
              <v-text-field
                v-model="form.alias_name"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Unit Utama</v-subheader
              >
            </v-col>
            <v-col cols="7" class="py-1">
              <v-select
                placeholder="- Semua Unit Utama -"
                v-model="form.unit_utama_id"
                :items="units"
                :loading="loadingUnit"
                outlined
                dense
                clearable
                item-text="singkatan"
                item-value="unit_utama_id"
                @change="changeUnitForm(1)"
                @click:clear="changeUnitForm(1)"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Unit Kerja</v-subheader
              >
            </v-col>
            <v-col cols="7" class="py-1">
              <v-select
                placeholder="- Semua Unit Kerja -"
                v-model="form.unit_kerja_2_id"
                :items="units2Form"
                :loading="loadingUnit"
                outlined
                dense
                clearable
                item-text="singkatan"
                item-value="unit_kerja_2_id"
              ></v-select>
            </v-col>
          </v-row>
          <v-card-actions class="mt-3">
            <v-spacer />
            <v-btn
              small
              outlined
              color="#717171"
              class="px-12"
              @click="visible = false"
              >Batal</v-btn
            >
            <v-btn
              small
              color="#FBB005"
              class="px-12 subtitle-2 text-capitalize"
              :loading="formLoading"
              type="submit"
              :disabled="!valid"
              form="form"
              >Simpan</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="visibleLog" persistent max-width="1000">
      <v-card class="pa-5 rounded-lg" id="card-custom">
        <v-btn @click="visibleLog = false" x-small icon class="float-right">
          <v-icon>close</v-icon>
        </v-btn>
        <v-card-text class="subtitle-2 px-0 font-weight-regular">
          Presensi Log
        </v-card-text>
        <v-divider class="mb-4" />
        <v-row>
          <v-col cols="12" xl="4" lg="4" md="4" sm="6" xs="12" class="pa-0">
            <v-menu
              v-model="menuStartLog"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateStartLog"
                  placeholder="Mulai"
                  append-icon="event"
                  outlined
                  dense
                  hide-details
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  class="mx-2"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dateStartLog"
                locale="id"
                no-title
                scrollable
                @input="menuStartLog = false"
              />
            </v-menu>
          </v-col>
          <v-col cols="12" xl="4" lg="4" md="4" sm="6" xs="12" class="pa-0">
            <v-menu
              v-model="menuEndLog"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateEndLog"
                  placeholder="Mulai"
                  append-icon="event"
                  outlined
                  dense
                  hide-details
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  class="mx-2"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dateEndLog"
                locale="id"
                no-title
                scrollable
                @input="menuEndLog = false"
              />
            </v-menu>
          </v-col>
          <v-col cols="12" xl="4" lg="4" md="4" sm="6" xs="12" class="pa-0">
            <v-text-field
              placeholder="Cari"
              v-model="searchLog"
              outlined
              dense
              hide-details
              clearable
              prepend-inner-icon="search"
              class="mx-2"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            class="text-capitalize caption headline-color"
            color="primary"
            :loading="loadingSyncLog"
            @click="syncLog"
            >Sync<v-icon class="ml-2" color="primary">sync</v-icon></v-btn
          >
        </v-card-actions>
        <v-data-table
          :headers="headersLog"
          :items="logs"
          :loading="loadingLog"
          :options.sync="optionsLog"
          :server-items-length="totalItemLog"
          :footer-props="{
            'items-per-page-options': rowsPerPageItems
          }"
          loading-text="Loading... Please wait"
        >
          <template v-slot:[`item.unit`]="{ item }">
            <div class="py-1" style="min-width: 150px">
              <p class="mb-0"><b>Unit Utama: </b>{{ item.nama_unit_utama }}</p>
              <p class="mb-0">
                <b>Unit Kerja 2: </b>{{ item.nama_unit_kerja_2 }}
              </p>
            </div>
          </template>
          <template v-slot:[`item.stt`]="{ item }">
            <v-icon color="success" v-if="item.stt == 1"
              >check_circle_outline</v-icon
            >
            <v-tooltip top v-else>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon color="error">info</v-icon>
                </v-btn>
              </template>
              <span>{{ item.message }}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.ip`]="{ item }">
            <div class="py-1" style="min-width: 150px">
              <p class="mb-0">
                <b>{{ item.alias_name }}</b>
              </p>
              <p class="mb-0">
                {{ item.ip }}
              </p>
            </div>
          </template>
          <template v-slot:[`item.waktu`]="{ item }">
            <div class="py-1" style="min-width: 150px">
              <p class="mb-0">{{ item.tanggal }} {{ item.jam }}</p>
            </div>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import MachineService from "@/services/resources/machine.service";
import DataUtamaService from "@/services/resources/data.utama.service";

export default {
  data() {
    return {
      visible: false,
      visibleLog: false,
      search: null,
      searchLog: null,
      valid: false,
      loadingUnit: false,
      loadingSyncLog: false,
      formLoading: false,
      selected: [],
      selection: true,
      selectedId: null,
      status: [
        { text: "Ya", value: "Y" },
        { text: "Tidak", value: "N" }
      ],
      units: [],
      units2: [],
      units2Form: [],
      unit: null,
      unit2: null,
      menuStart: false,
      dateStart: new Date().toISOString().substr(0, 10),
      menuEnd: false,
      dateEnd: new Date().toISOString().substr(0, 10),
      menuStartLog: false,
      dateStartLog: new Date().toISOString().substr(0, 10),
      menuEndLog: false,
      dateEndLog: new Date().toISOString().substr(0, 10),
      isEdit: false,
      headersLog: [
        {
          text: "IP Address/Name",
          value: "ip",
          sortable: false,
          align: "left"
        },
        {
          text: "Unit Kerja",
          value: "unit",
          sortable: false,
          align: "left"
        },
        {
          text: "PIN",
          value: "PIN",
          sortable: false,
          align: "left"
        },
        {
          text: "Waktu",
          value: "waktu",
          sortable: false,
          align: "left"
        },
        {
          text: "Status",
          value: "stt",
          sortable: false,
          align: "left"
        }
      ],
      headers: [
        {
          text: "IP Address/Name",
          value: "ip",
          sortable: false,
          align: "left"
        },
        {
          text: "Unit Kerja",
          value: "unit",
          sortable: false,
          align: "left"
        },
        {
          text: "Status",
          value: "status",
          sortable: false,
          align: "left"
        },
        { text: "Online", value: "online", sortable: false, align: "left" },
        {
          text: "Last Sync",
          value: "sync_terakhir",
          sortable: false,
          align: "left"
        },
        {
          text: "",
          value: "actions",
          sortable: false,
          align: "left"
        }
      ],
      form: {
        id: null,
        ip: null,
        alias_name: null,
        unit_utama_id: null,
        unit_kerja_2_id: null
      },
      totalItem: 0,
      totalItemLog: 0,
      pageCount: 0,
      pageCountLog: 0,
      loading: true,
      loadingLog: false,
      options: {
        sortBy: ["ip"],
        sortDesc: [false]
      },
      optionsLog: {
        sortBy: ["PIN"],
        sortDesc: [false]
      },
      rowsPerPageItems: [10, 20, 30, 40],
      machines: [],
      logs: []
    };
  },
  watch: {
    options: {
      handler() {
        this.fetchListDebounce(this.getListDevice);
      },
      deep: true
    },
    optionsLog: {
      handler() {
        this.fetchListDebounce(this.getListLog);
      },
      deep: true
    },
    paginationProperty: function() {
      this.options.page = 1;
      this.fetchListDebounce(this.getListDevice);
    },
    paginationPropertyLog: function() {
      this.optionsLog.page = 1;
      this.fetchListDebounce(this.getListLog);
    },
    visible(val) {
      if (!val) {
        this.$refs.form.reset();
      }
    }
  },
  computed: {
    paginationProperty() {
      return [this.search, this.unit, this.unit2].join();
    },
    paginationPropertyLog() {
      return [
        this.selectedId,
        this.searchLog,
        this.dateStartLog,
        this.dateEndLog
      ].join();
    }
  },
  methods: {
    changeUnit(type = 1) {
      if (type == 1) {
        this.units2 = this.units.find(
          d => d.unit_utama_id == this.unit
        )?.unit_kerja2;
        this.unit2 = null;
      }
    },
    changeUnitForm(type = 1) {
      if (type == 1) {
        this.units2Form = this.units.find(
          d => d.unit_utama_id == this.form.unit_utama_id
        )?.unit_kerja2;
        this.form.unit_kerja_2_id = null;
      }
    },
    populateUnit() {
      this.units2Form = this.units.find(
        d => d.unit_utama_id == this.form.unit_utama_id
      )?.unit_kerja2;
    },
    handleEdit(item) {
      this.visible = true;
      this.isEdit = true;
      this.getDetailDevice(item.id);
    },
    save() {
      if (this.$refs.form.validate()) {
        let payload = {
          action: this.form.id ? 2 : 1,
          id: this.form.id,
          ip: this.form.ip,
          unit_utama_id: this.form.unit_utama_id,
          unit_kerja_2_id: this.form.unit_kerja_2_id,
          alias_name: this.form.alias_name
        };
        this.saveMachine(payload);
      }
    },
    syncManualBulk() {
      this.selected.map(item => {
        const selectedIdx = this.machines.findIndex(d => d.id == item.id);
        this.syncManual(item, selectedIdx);
      });
    },
    handleDelete(item) {
      this.$confirm({
        title: "Konfirmasi",
        message: `Apakah anda yakin akan menghapus data ini?`,
        button: {
          no: "Batal",
          yes: "Ya"
        },
        callback: confirm => {
          if (confirm) {
            this.deleteDevice({
              id: item.id
            });
          }
        }
      });
    },
    refreshStatus(item) {
      const index = this.machines.findIndex(data => data === item);
      this.getStatusDevice(item, index);
    },
    handleClickLog(item) {
      this.selectedId = item.id;
      this.visibleLog = true;
    },
    // Service
    async getListUnit() {
      try {
        this.loadingUnit = true;
        await DataUtamaService.getUnitKerjaAll({
          platform: this.isPresensi ? "presensi" : "simpeg"
        })
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.units = data;
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingUnit = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getDetailDevice(id) {
      try {
        this.formLoading = true;
        await MachineService.getDetailDevice(id)
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.form.id = data.id;
              this.form.ip = data.ip;
              this.form.alias_name = data.alias_name;
              this.form.unit_utama_id = data.unit_utama_id;
              this.form.unit_kerja_2_id = data.unit_kerja_2_id;
              this.populateUnit();
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.formLoading = false));
      } catch (err) {
        console.error(err);
      }
    },
    async saveMachine(payload) {
      try {
        this.formLoading = true;
        await MachineService.saveDevice(payload)
          .then(response => {
            const { status, message } = response.data;
            if (status) {
              this.visible = false;
              this.getListDevice();
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "success"
              });
            } else {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "error"
              });
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.formLoading = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getListDevice() {
      const { page, itemsPerPage, sortBy, sortDesc } = this.options;
      let orderBy = [];
      sortBy.map((d, i) => {
        let sort = sortDesc[i] ? "desc" : "asc";
        orderBy = [...orderBy, d, sort];
        return d;
      });
      try {
        this.loading = true;
        await MachineService.getListDevice({
          filter: {
            search: this.search,
            unit_utama_id: this.unit,
            unit_kerja_2_id: this.unit2
          },
          orderBy,
          pageCurrent: page,
          dataPerPage: itemsPerPage
        })
          .then(response => {
            const { status, data } = response.data;
            const { list, pageTotal, countTotal } = data;
            if (status) {
              let machines = list;
              machines.map((data, index) => {
                data.no = itemsPerPage * (page - 1) + (index + 1);
                data.online = null;
                data.loading = false;
                data.loadingSync = false;
                return data;
              });

              this.machines = machines;
              this.totalItem = parseInt(countTotal);
              this.pageCount = parseInt(pageTotal);

              this.machines.map((item, index) =>
                this.getStatusDevice(item, index)
              );
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loading = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getListLog() {
      const { page, itemsPerPage, sortBy, sortDesc } = this.optionsLog;
      let orderBy = [];
      sortBy.map((d, i) => {
        let sort = sortDesc[i] ? "desc" : "asc";
        orderBy = [...orderBy, d, sort];
        return d;
      });
      try {
        this.loadingLog = true;
        await MachineService.getAbsensiLog({
          id_mesin: this.selectedId,
          date_start: this.dateStartLog
            ? this.$moment(this.dateStartLog).format("YYYY-MM-DD")
            : "",
          date_end: this.dateEndLog
            ? this.$moment(this.dateEndLog).format("YYYY-MM-DD")
            : "",
          filter: {
            search: this.searchLog
          },
          orderBy,
          pageCurrent: page,
          dataPerPage: itemsPerPage
        })
          .then(response => {
            const { status, data } = response.data;
            const { list, pageTotal, countTotal } = data;
            if (status) {
              let logs = list;
              logs.map((data, index) => {
                data.no = itemsPerPage * (page - 1) + (index + 1);
                return data;
              });

              this.logs = logs;
              this.totalItemLog = parseInt(countTotal);
              this.pageCountLog = parseInt(pageTotal);
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingLog = false));
      } catch (err) {
        console.error(err);
      }
    },
    async syncLog() {
      try {
        this.loadingSyncLog = true;
        await MachineService.syncAbsensiLog({
          id_mesin: this.selectedId,
          date_start: this.dateStartLog
            ? this.$moment(this.dateStartLog).format("YYYY-MM-DD")
            : "",
          date_end: this.dateEndLog
            ? this.$moment(this.dateEndLog).format("YYYY-MM-DD")
            : ""
        })
          .then(response => {
            const { status, message } = response.data;
            if (status) {
              this.visible = false;
              this.getListLog();
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "success"
              });
            } else {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "error"
              });
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingSyncLog = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getStatusDevice(item, index) {
      const { ip } = item;
      try {
        this.machines[index].loading = true;
        this.machines[index].error = null;
        await MachineService.getStatusDevice({ ip })
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.machines[index].online = data;
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.machines[index].loading = false));
      } catch (err) {
        console.error(err);
      }
    },
    async syncManual(item, index) {
      try {
        this.machines[index].loadingSync = true;
        this.machines[index].errorSync = null;
        await MachineService.syncManual({
          ip: item.ip,
          unit_utama_id: item.unit_utama_id,
          unit_kerja_2_id: item.unit_kerja_2_id,
          date_start: this.dateStart,
          date_end: this.dateEnd
        })
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              const { online, status, sync_terakhir } = data;
              this.machines[index].status = status;
              this.machines[index].online = online;
              this.machines[index].sync_terakhir = sync_terakhir;
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.machines[index].loadingSync = false));
      } catch (err) {
        console.error(err);
      }
    },
    async deleteDevice(payload) {
      try {
        this.formLoading = true;
        await MachineService.deleteDevice(payload)
          .then(response => {
            const { status, message } = response.data;
            if (status) {
              this.getListDevice();
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "success"
              });
            } else {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "error"
              });
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.formLoading = false));
      } catch (err) {
        console.error(err);
      }
    }
  },
  mounted() {
    this.getListUnit();
  }
};
</script>
<style lang="scss">
#table-custom.v-data-table {
  color: #717171 !important;
  .v-data-table__wrapper > table > thead {
    background-color: #fdfdfd;
  }
  .v-data-table__wrapper > table > thead > tr > th {
    font-size: 0.775rem;
    font-weight: 400;
  }
  .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 0.775rem;
  }
}
#card-custom .v-input {
  font-size: 0.775rem;
}
#btn-type .v-btn--contained {
  background-color: #ffffff !important;
  .v-btn__content {
    color: #717171 !important;
  }
}
#btn-type .v-btn--active {
  background-color: #1792e6 !important;
  .v-btn__content {
    color: #ffffff !important;
  }
}
</style>
