import BaseInstance from "../base.instance";
import {
  PRESENSI_MACHINE_DATA_NIP_LIST,
  PRESENSI_MACHINE_DATA_NIP_DETAIL,
  PRESENSI_MACHINE_DATA_NIP_SAVE,
  PRESENSI_MACHINE_DATA_NIP_DELETE,
  PRESENSI_MACHINE_DEVICE_LIST,
  PRESENSI_MACHINE_DEVICE_DETAIL,
  PRESENSI_MACHINE_DEVICE_SAVE,
  PRESENSI_MACHINE_DEVICE_DELETE,
  PRESENSI_MACHINE_DEVICE_STATUS,
  PRESENSI_MACHINE_DEVICE_MANUAL_SYNCHRON,
  PRESENSI_MACHINE_DEVICE_ABSENSI_LOG,
  PRESENSI_MACHINE_DEVICE_SYNC_LOG
} from "../constants";

const MachineService = {
  getListDataNIP(data) {
    return BaseInstance.post(PRESENSI_MACHINE_DATA_NIP_LIST, data);
  },
  getDetailDataNIP(id) {
    return BaseInstance.fetch(PRESENSI_MACHINE_DATA_NIP_DETAIL, id);
  },
  saveDataNIP(data) {
    return BaseInstance.post(PRESENSI_MACHINE_DATA_NIP_SAVE, data);
  },
  deleteDataNIP(data) {
    return BaseInstance.post(PRESENSI_MACHINE_DATA_NIP_DELETE, data);
  },
  getListDevice(data) {
    return BaseInstance.post(PRESENSI_MACHINE_DEVICE_LIST, data);
  },
  getDetailDevice(id) {
    return BaseInstance.fetch(PRESENSI_MACHINE_DEVICE_DETAIL, id);
  },
  saveDevice(data) {
    return BaseInstance.post(PRESENSI_MACHINE_DEVICE_SAVE, data);
  },
  deleteDevice(data) {
    return BaseInstance.post(PRESENSI_MACHINE_DEVICE_DELETE, data);
  },
  getStatusDevice(params) {
    return BaseInstance.query(PRESENSI_MACHINE_DEVICE_STATUS, { params });
  },
  syncManual(data) {
    return BaseInstance.post(PRESENSI_MACHINE_DEVICE_MANUAL_SYNCHRON, data);
  },
  getAbsensiLog(data) {
    return BaseInstance.post(PRESENSI_MACHINE_DEVICE_ABSENSI_LOG, data);
  },
  syncAbsensiLog(data) {
    return BaseInstance.post(PRESENSI_MACHINE_DEVICE_SYNC_LOG, data);
  }
};

export default MachineService;
